<template>
  <list-condition-template ref="table" :rows="rows" :total="total"
  @page-info-change="handlePageInfoChange" v-model:listQuery="listQuery" :index-column="true">
    <template #condition>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="品类名称/代码" v-model.trim="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
      </el-row>
    </template>
    <template #columns>
      <el-table-column min-width="150px" label="品类名称" sortable="custom" property="name" align="center">
        <template #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="品类描述" align="center" property="description">
        <template #default="scope">
          <span>{{scope.row.description}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="品类代码" align="center" property="categoryCode">
        <template #default="scope">
          <span>{{scope.row.categoryCode}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="添加时间" align="center" property="createTime">
        <template #default="scope">
          <span>{{scope.row.createTime}}</span>
        </template>
      </el-table-column>
       <el-table-column min-width="150px" label="更新时间" align="center" property="updateTime">
        <template #default="scope">
          <span>{{scope.row.updateTime}}</span>
        </template>
      </el-table-column>     
      <el-table-column align="center" label="操作" min-width="300px" class-name="fixed-width">
        <template #default="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)" v-waves>编辑</el-button>
          <el-button type="danger" size="mini" @click="DeleteMaterialCategory(scope.row)" v-waves>删除</el-button>
        </template>
      </el-table-column>
    </template>


    <el-dialog :title="materialCategoryDialog.title" v-model="materialCategoryDialog.show" @close="handleMaterialCategoryDialogClose">
       <el-form size="mini" ref="form" :model="materialCategory" :rules="materialCategoryDialog.rules" label-width="120px" status-icon>
        <el-form-item label="品类名称" prop="name">
          <el-input v-model.trim="materialCategory.name" :maxlength="20" show-word-limit  placeholder="请输入品类名称"></el-input>
        </el-form-item>
        <el-form-item  label="品类代码" prop="categoryCode">
          <el-input  v-model.trim="materialCategory.categoryCode" :minlength="7" :maxlength="7" show-word-limit placeholder="品类代码"></el-input>
        </el-form-item>
        <el-form-item label="品类描述" prop="description">
          <el-input type="textarea" v-model.trim="materialCategory.description" :maxlength="255" :rows="2" show-word-limit placeholder="请输入品类描述"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button v-if="this.materialCategory.id" type="primary" @click="onSubmit(true)" v-waves>保存</el-button>
          <el-button v-if="!this.materialCategory.id" type="primary" @click="onSubmit(true)" v-waves>保存并继续</el-button>
          <el-button v-if="!this.materialCategory.id" type="primary" @click="onSubmit(false)" v-waves>保存并退出</el-button>
          <el-button v-waves @click="materialCategoryInfoCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </list-condition-template>
</template>

<script>
import categoryApi from '@/apis/model/category'
import commonApi from '@/apis/common'
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'

export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      rows: null,
      total: 0,
      listQuery: {
        condition: {
          keyword: null
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      materialCategory: {
        id: null,
        name: '',
        description: '',
        type: null,
        categoryCode: '',
        assistCode: ''
      },
      materialCategoryDialog: {
        show: false,
        title: '新建品类',
        rules: {
          name: [{
              required: true,
              message: '请输入品类名称'
            }, 
            // {
            //   validator: (rule, value, callback) => {
            //     var regEn = /[`~!@#$%^&*()+<>?:"{},.\/;'[\]]/im
            //     var regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im
            //     if(regEn.test(value) || regCn.test(value)){
            //       callback(new Error('不支持特殊符号！'))
            //     } else {
            //       callback()
            //     }
            //   },
            //   trigger: 'blur'
            // },
            // {
            //   pattern:/^[a-zA-Z0-9\u4e00-\u9fa5_\-]+$/,
            //   message:"只能是数字、字母、汉字和下划线、短横线"
            // },
            {
            validator: (rule, value, callback) => {
              commonApi.verifyMaterialCategoryName(value, this.materialCategory.id).then(res => {
                if (!res.data) {
                  callback(new Error('品类名称【' + value + '】已存在'))
                } else {
                  callback()
                }
              })
            },
            trigger: 'blur'
          }],
        categoryCode:[
          {
            required: true,
            message: '请输入品类代码'
          }, 
          {
            pattern: /^[0-9A-Za-z]{7}$/,
            message: '只能是7位数字、字母或数字和字母的组合'
          },
          {
          validator: (rule, value, callback) => {
            commonApi.verifyMaterialCategoryCode(value, this.materialCategory.id).then(res => {
              if (!res.data) {
                callback(new Error('品类代码【' + value + '】已存在'))
              } else {
                callback()
              }
            })
          },
          trigger: 'blur'
        }]
        }
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    /**
     * 加载数据
     */
    getList () {
      categoryApi
        .getQuery(this.listQuery, 'page')
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    /**
     * 搜索条件变更处理
     */
    handleFilter () {
      this.getList()
    },
    /**
     * 分页信息变动事件处理
     * @param  {Object} pageInfo 分页信息
     */
    handlePageInfoChange (pageInfo) {
      this.pageInfo = pageInfo
      this.getList()
    },
    /**
     * 新增按钮点击处理
     */
    handleCreate () {
      this.materialCategoryDialog.title = '新建品类'
      this.materialCategoryDialog.show = true
    },
    /**
     * 修改按钮点击处理
     * @param {*} row 参数
     */
    handleUpdate (row) {
      this.materialCategoryDialog.show = true
      this.materialCategoryDialog.title = '编辑品类'
      this.materialCategory.id = row.id
      this.getMaterialCategory()
    },
    /**
       * 取消按钮处理
       */
    materialCategoryInfoCancel () {
      this.materialCategoryDialog.show = false
      this.resetForm()
      this.handleFilter()
    },
    /**
       * 提交数据
       * @param {*} goon 新增后是否继续新增
       */
    onSubmit (goon) {
      this.$refs.form.validate().then(response => {
        if (!response) {
          return
        }
        if (!this.materialCategory.id) {
          categoryApi.insert(this.materialCategory).then(response => {
            this.$message.success({
              title: '成功',
              message: '成品品类创建成功',
              type: 'success'
            })
            if (goon) {
              this.resetForm()
            } else {
              this.materialCategoryDialog.show = false
              this.handleFilter()
            }
          })
        } else {
          categoryApi.update(this.materialCategory).then(response => {
            this.$message.success({
              title: '成功',
              message: '成品品类更新成功',
              type: 'success'
            })
            this.materialCategoryDialog.show = false
            this.handleFilter()
          })
        }
      })
    },
    /**
     * 删除按钮点击处理
     * @param {*} row 参数
     */
    DeleteMaterialCategory (row) {
      this.$confirm('删除后无法恢复,确定要删除该品类吗？', '警告', {
        type: 'warning'
      }).then(() => {
        categoryApi.delete(row.id).then(res => {
          if (res.data) {
            this.handleFilter()
          } else {
            this.$message.error({
              title: '发生错误',
              message: res.msg === '' ? '删除品类失败，请稍后重试！！！' : res.msg,
              duration: 0,
              position: 'bottom-right'
            })
          }
        })
      })
      this.handleFilter()
    },
    handleMaterialCategoryDialogClose () {
      this.resetForm()
    },
    resetForm () {
      this.$refs.form.resetFields()
      this.materialCategory.id = null
    },
    /**
       * 获取管理员信息
       */
    getMaterialCategory () {
      if (this.materialCategory.id) {
        categoryApi.getById(this.materialCategory.id).then(response => {
          this.materialCategory = response.data
        })
      }
    }
  }
}
</script>
